import "./Sidebar.css";
import { React, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../components/Images/flicklogo.png";
import add_list_01 from "../../components/Images/sidebar_icon/add_list_01.svg";
import master_list_01 from "../../components/Images/sidebar_icon/master_list_01.svg";
import Ads_Categories_01 from "../../components/Images/sidebar_icon/Ads_Categories_01.svg";
import Ads_Compaigns_01 from "../../components/Images/sidebar_icon/Ads_Compaigns_01.svg";
import Ads_Plans_01 from "../../components/Images/sidebar_icon/Ads_Plans_01.svg";
import ads_revenue_01 from "../../components/Images/sidebar_icon/ads_revenue_01.svg";
import ads_time_slot_01 from "../../components/Images/sidebar_icon/ads_time_slot_01.svg";
import analytics_01 from "../../components/Images/sidebar_icon/analytics_01.svg";
import Artist_Management_01 from "../../components/Images/sidebar_icon/Artist_Management_01.svg";
import Audio_Management_01 from "../../components/Images/sidebar_icon/Audio_Management_01.svg";
import Cache_Management_01 from "../../components/Images/sidebar_icon/Cache_Management_01.svg";
import Calender_Events_01 from "../../components/Images/sidebar_icon/Calender_Events_01.svg";
import channel_partner_01 from "../../components/Images/sidebar_icon/channel_partner_01.svg";
import content_partner_01 from "../../components/Images/sidebar_icon/content_partner_01.svg";
import Dashboard_01 from "../../components/Images/sidebar_icon/Dashboard_01.svg";
import debug_01 from "../../components/Images/sidebar_icon/debug_01.svg";
// import File_Manager_01 from "../../components/Images/sidebar_icon/File_Manager_01.svg";
import Language_01 from "../../components/Images/sidebar_icon/Language_01.svg";
import Manage_Advertiser_01 from "../../components/Images/sidebar_icon/Manage_Advertiser_01.svg";
import Manage_LiveStream_01 from "../../components/Images/sidebar_icon/Manage_LiveStream_01.svg";
import Menu_01 from "../../components/Images/sidebar_icon/Menu_01.svg";
import Pages_01 from "../../components/Images/sidebar_icon/Pages_01.svg";
import Payment_Management_01 from "../../components/Images/sidebar_icon/Payment_Management_01.svg";
import plans_01 from "../../components/Images/sidebar_icon/plans_01.svg";
import Player_ui_01 from "../../components/Images/sidebar_icon/Player_ui_01.svg";
import See_Log_Activity_01 from "../../components/Images/sidebar_icon/See_Log_Activity_01.svg";
import Series_Episode_01 from "../../components/Images/sidebar_icon/Series_Episode_01.svg";
import Settings_01 from "../../components/Images/sidebar_icon/Settings_01.svg";
import slider_01 from "../../components/Images/sidebar_icon/slider_01.svg";
import user_01 from "../../components/Images/sidebar_icon/user_01.svg";
import Video_Management_01 from "../../components/Images/sidebar_icon/Video_Management_01.svg";
import contact_request from "../../components/Images/sidebar_icon/contact_request.svg";
import { useTranslation } from 'react-i18next';
import MessageBox from "../../components/CommonComponents/MessageBox";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import axios from "axios"
import { advertiserURL, analyticsURL, artistURL, audioURL, channelPartnerURL, faqURL, contentPartnerURL, languageURL, liveURL, menuURL, pagesURL, paymentURL, plansURL, seriesURL, settingsURL, userURL, videoURL } from "./NavigationData";
import loadings from "../../../src/components/Images/loading/gif_loadings.gif";
import Defaultloadinggif from "../Others/Defaultloadinggif";
import getMenuDatalabel from "../Others/Labelmenuapi";

function Sidebar({ toggleClassName, toggleAction }) {
  const [dspsettings, setDspsettings] = useState([]);
  const [logoimage, setLogoimage] = useState();

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [menuDatalabel, setMenuDatalabel] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getMenuDatalabel();
        setMenuDatalabel(data?.sidebar_MenuLabel_array);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reqData = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/Site-ThemeSetting/index`, { headers: headers });
        setDspsettings(reqData?.data?.Site_theme_setting[0]);
        setLogoimage(reqData?.data?.Site_theme_setting[0]?.light_mode_logo);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="sidebarMenu ">
      <div className={`adminSideBarLeft  ${toggleClassName ? "active" : ""} theme-bg-color `} >
        <NavLink className="sideBarBrand d-lg-block px-2 px-lg-0 d-flex align-items-center theme-bg-color" to={"/"}>
          {logoimage === null ? (
            <Defaultloadinggif></Defaultloadinggif>
          ) : (
            <img src={logoimage} alt="flogo" className="mx-auto d-block py-3 object-fit-contain" style={{ width: "200px", height: "80px" }} loading="lazy" />
          )}
          <IoMdClose onClick={() => toggleAction()} className="d-lg-none" style={{ width: "20px", height: "20px", fill: "#000" }} />
        </NavLink>
        <ul className="list-unstyled px-2 " >
          <li className="nav-item mb-4">
            <NavLink to={"/"} className="d-flex align-items-center sideMenu   position-relative"  >
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g>
                    <rect class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75pxa" }} x="1" y="1.16" width="10.11" height="7.14" rx="0.89" />
                    <rect class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75pxa" }} x="12.89" y="16.02" width="10.11" height="7.14" rx="0.89" />
                    <rect class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75pxa" }} x="1" y="10.67" width="10.11" height="12.49" rx="1.18" />
                    <rect class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75pxa" }} x="12.89" y="1.16" width="10.11" height="12.49" rx="1.18" />
                  </g>
                </svg>
              </div>
              <MessageBox text='Dashboard' classname="ms-2 text-break" />
            </NavLink>
          </li>

          {/* <li className="nav-item mb-4">
            <MessageBox text='Content Libraries' classname='sideBarHeading theme-text-color  px-3 pb-2 text-start d-block' />
            <NavLink className="d-flex align-items-center sideMenu  position-relative" to={"/media-library"}>
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g>
                    <path class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75px" }} d="M21.64,22a.16.16,0,0,1-.09.16.21.21,0,0,1-.19,0L5.06,12.77A.18.18,0,0,1,5,12.52a.11.11,0,0,1,.08-.08l16.3-9.39h.19a.16.16,0,0,1,.09.16Z" />
                    <path class="cls-2" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75px" }} d="M1.77,23.31V1" />
                  </g>
                </svg>
              </div>
              <MessageBox text='All Library' classname="ms-2 text-break" />
            </NavLink>
          </li> */}

          <li className="nav-item mb-4">
            <MessageBox text='Master Videos ' classname='sideBarHeading theme-text-color  px-3 pb-2 text-start d-block' />
            <NavLink className="d-flex align-items-center sideMenu  position-relative" to="/master-list">
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g>
                    <rect class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "0.75px" }} x="1.5" y="8.36" width="22.01" height="12.89" rx="0.94" />
                    <polygon class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "0.75px" }} points="10.4 17.39 10.4 11.73 14.6 14.56 10.4 17.39" />
                    <line class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "0.75px" }} x1="3" y1="5.5" x2="22" y2="5.5" />
                    <line class="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "0.75px" }} x1="6" y1="2.5" x2="19" y2="2.5" />
                  </g>
                </svg>
              </div>
              <MessageBox text='Master Videos List' classname="ms-2 text-break" />
            </NavLink>
          </li>

          <li className="nav-item mb-4 " >
            <MessageBox text='Videos' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center justify-content-between sideMenu position-relative  px-3  mb-2 ${videoURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Video-Management" aria-expanded="false" aria-controls="Video-Management"  >
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g>
                    <rect class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x="1" y="3.16" width="22.01" height="12.89" rx="0.94" /> <g>
                      <line class="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="22.78" y1="20.82" x2="13.33" y2="20.82" />
                      <line class="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="10.67" y1="20.82" x2="1.22" y2="20.82" />
                      <path class="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M13.33,20.82a1.33,1.33,0,1,0-2.66,0" />
                      <path class="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M10.67,20.82a1.33,1.33,0,1,0,2.66,0" />
                    </g>
                    <polygon class="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} points="9.9 12.2 9.9 6.54 14.1 9.37 9.9 12.2" />
                  </g>
                </svg>
                <MessageBox text='Video Management' classname="ms-2 text-break" />
              </div>

              <IoIosArrowDown />
            </div>
            <ul id="Video-Management" className={`collapse p-0 mt-2 list-unstyled ${videoURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`} >
              {videoURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative px-3  ${seriesURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Series-Episode" aria-expanded="false" aria-controls="Series-Episode">
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g>
                    <g>
                      <rect class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x="0.81" y="1" width="22.38" height="19.02" rx="4.08" />
                      <line class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="1.12" y1="15.38" x2="23.17" y2="15.38" />
                      <line class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="9.63" y1="20.16" x2="8.98" y2="22.8" />
                      <line class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="14.44" y1="20.16" x2="14.92" y2="22.8" />
                      <line class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="6.71" y1="23.31" x2="17.36" y2="23.31" />
                    </g>
                    <polygon class="cls-1" points="10.2 11.5 10.2 5.49 15.02 8.43 10.2 11.5" />
                  </g>
                </svg>
                <MessageBox text='Series & Episode' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Series-Episode" className={`collapse p-0 mt-2 list-unstyled ${seriesURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `}>
              {seriesURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Live Stream' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3   ${liveURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'} `} data-bs-toggle="collapse" data-bs-target="#Live-Stream" aria-expanded="false" aria-controls="Live-Stream"  >
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g>
                    <g>
                      <path class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M19.29,3.57A11.21,11.21,0,0,1,23,9.83a10.82,10.82,0,0,1,.25,2.34,11.24,11.24,0,0,1-4,8.57" />
                      <path class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M16.53,6.94a6.93,6.93,0,0,1,0,10.48" />
                    </g>
                    <g>
                      <path class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M4.71,3.57A11.21,11.21,0,0,0,1,9.83a10.82,10.82,0,0,0-.25,2.34,11.24,11.24,0,0,0,4,8.57" />
                      <path class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M7.47,6.94a6.93,6.93,0,0,0,0,10.48" />
                    </g>
                    <circle cx="12.13" cy="12.16" r="1.37" />
                  </g>
                </svg>
                <MessageBox text='Manage Live Stream' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Live-Stream" className={`collapse p-0 mt-2 list-unstyled ${liveURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
              {liveURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative px-1 ">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Audio' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center justify-content-between sideMenu position-relative mb-2 px-3  ${audioURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Audio-Management" aria-expanded="false" aria-controls="Audio-Management"  >
              <div>
                <svg>
                  <g>
                    <g>
                      <path class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M19.38,3.15a10.37,10.37,0,0,1,3.53,6,7.83,7.83,0,0,1,.16,1.17.42.42,0,0,0,0,.15v1.36l-.12.84a10.16,10.16,0,0,1-1.54,4.12,10.31,10.31,0,0,1-2.09,2.38" />
                      <path class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M17.54,5.4A7.55,7.55,0,0,1,20,9.59a7.37,7.37,0,0,1,.16,1.56,7.51,7.51,0,0,1-2.64,5.72" />
                      <path class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M15.7,7.65a4.63,4.63,0,0,1,0,7" />
                    </g>
                    <g>
                      <rect class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x="1.55" y="6.27" width="3.33" height="9.77" />
                      <polyline class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} points="5.09 6.23 11.79 1 11.79 21.31 4.87 15.91" />
                    </g>
                  </g>
                </svg>

                <MessageBox text='Audio Management' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Audio-Management" className={`collapse p-0 mt-2 list-unstyled ${audioURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`}>
              {audioURL.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative px-3  ${artistURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Artist-Management" aria-expanded="false" aria-controls="Artist-Management">
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <g>
                    <circle class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeWidth: "0.75px", stroke: "currentColor" }} cx="3.93" cy="20.22" r="2.93" />
                    <circle class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeWidth: "0.75px", stroke: "currentColor" }} cx="20.07" cy="17.29" r="2.93" />
                    <polyline class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeWidth: "0.75px", stroke: "currentColor" }} points="6.87 20.22 6.87 1.16 23 1.16 23 17.29" />
                    <line class="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="6.87" y1="5.56" x2="23" y2="5.56" />
                  </g>
                </svg>

                <MessageBox text='Trainer Management' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Artist-Management" className={`collapse p-0 mt-2 list-unstyled ${artistURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`} >
              {artistURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4 " >
            <MessageBox text='Accounts' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center justify-content-between sideMenu position-relative mb-2 px-3  ${userURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#User" aria-expanded="false" aria-controls="User"  >
              <div>
                <img src={user_01} alt="flogo" className="" />
                <MessageBox text='User' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="User" className={`collapse p-0 mt-2 list-unstyled ${userURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `}>
              {userURL.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
            <div className={`nav-link  d-flex align-items-center justify-content-between  sideMenu position-relative px-3  ${menuURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Menu" aria-expanded="false" aria-controls="Menu">
              <div>
                <img src={Menu_01} alt="flogo" className="" />
                <MessageBox text='Menu' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Menu" className={`collapse p-0 mt-2 list-unstyled ${menuURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
              {menuURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4 " >
            <MessageBox text='Language & Equipment' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3  ${languageURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Language" aria-expanded="false" aria-controls="Language"  >
              <div>
                <img src={Language_01} alt="flogo" className="" />
                <MessageBox text='Manage Equipment' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Language" className={`collapse p-0 mt-2 list-unstyled ${languageURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}  `} >
              {languageURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative px-1 ">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4">
            <MessageBox text='Slider' classname='sideBarHeading theme-text-color  px-3 pb-2 text-start d-block' />
            <NavLink className="d-flex align-items-center sideMenu  px-3  position-relative" to={"/sliders"}>
              <div>
                <img src={slider_01} alt="flogo" className="" />
              </div>
              <MessageBox text='Slider' classname="ms-2 text-break" />
            </NavLink>
          </li>

          <li className="nav-item mb-4">
            <MessageBox text='Site' classname='sideBarHeading theme-text-color  px-3 pb-2 text-start d-block' />
            <NavLink className="d-flex align-items-center sideMenu mb-2 px-3  position-relative" to={"/players"}>
              <div>
                <img src={Player_ui_01} alt="flogo" className="" />
              </div>
              <MessageBox text='Player UI' classname="ms-2 text-break" />
            </NavLink>

            <NavLink className="d-flex align-items-center sideMenu  px-3  position-relative" to={"/music-settings"}>
              <div>
                <img src={Player_ui_01} alt="flogo" className="" />
              </div>
              <MessageBox text='Music UI' classname="ms-2 text-break" />
            </NavLink>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Partners' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center justify-content-between sideMenu position-relative mb-2 px-3  ${contentPartnerURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#contentPartner" aria-expanded="false" aria-controls="contentPartner"  >
              <div>
                <img src={content_partner_01} alt="flogo" className="" />

                <MessageBox text='Content Partner' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="contentPartner" className={`collapse p-0 mt-2 list-unstyled  ${contentPartnerURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`} >
              {contentPartnerURL.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative px-3  ${channelPartnerURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#channelPartner" aria-expanded="false" aria-controls="channelPartner">
              <div>
                <object id="external-svg" type="image/svg+xml" data={channel_partner_01}>
                  <img src={channel_partner_01} alt="flogo" className="" />
                </object>
                <MessageBox text='Channel Partner' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="channelPartner" className={`collapse p-0 mt-2 list-unstyled ${channelPartnerURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`} >
              {channelPartnerURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Pages' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3  ${pagesURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Pages" aria-expanded="false" aria-controls="Pages"  >
              <div>
                <img src={Pages_01} alt="flogo" className="" />
                <MessageBox text='Pages' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Pages" className={`collapse p-0 mt-2 list-unstyled ${pagesURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
              {pagesURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Plans' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3  ${plansURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Plans" aria-expanded="false" aria-controls="Plans"  >
              <div>
                <img src={plans_01} alt="flogo" className="" />
                <MessageBox text='Plans' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Plans" className={`collapse p-0 mt-2 list-unstyled ${plansURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
              {plansURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Payment' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3  ${paymentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Payment-Management" aria-expanded="false" aria-controls="Payment-Management"  >
              <div>
                <img src={Payment_Management_01} alt="flogo" className="" />
                <MessageBox text='Payment Management' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Payment-Management" className={`collapse p-0 mt-2 list-unstyled ${paymentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
              {paymentURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Analytics' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3  ${analyticsURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Analytics" aria-expanded="false" aria-controls="Analytics"  >
              <div>
                <img src={analytics_01} alt="flogo" className="" />
                <MessageBox text='Analytics' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Analytics" className={`collapse p-0 mt-2 list-unstyled ${analyticsURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
              {analyticsURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative px-3 ">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Settings' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3  ${settingsURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Settings" aria-expanded="false" aria-controls="Settings"  >
              <div>
                <img src={Settings_01} alt="flogo" className="" />
                <MessageBox text='Settings' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Settings" className={`collapse p-0 mt-2 list-unstyled ${settingsURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
              {settingsURL?.map(data => {
                if (dspsettings?.audio_content_right_checkout && data?.url === '/dsp-settings') {
                  return null;
                }
                return (
                  <li className=" mb-2" >
                    <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative px-3 ">
                      <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                    </NavLink>
                  </li>)
              })}
            </ul>
          </li>

          <li className="nav-item mb-4" >
            <MessageBox text='Ads Management' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
            <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3  ${advertiserURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#Ads-Management" aria-expanded="false" aria-controls="Ads-Management"  >
              <div>
                <img src={Manage_Advertiser_01} alt="flogo" className="" />
                <MessageBox text='Manage Advertiser' classname="ms-2 text-break" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Ads-Management" className={`collapse p-0 mt-2 list-unstyled ${advertiserURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
              {advertiserURL?.map((data, index) =>
                <li className=" mb-2" >
                  <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative px-3 ">
                    <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                  </NavLink>
                </li>)}
            </ul>
            <li className="nav-item mt-2">
              <NavLink to={"/ads_categories"} className="d-flex align-items-center sideMenu  px-3 position-relative"  >
                <div>
                  <img src={Ads_Categories_01} alt="flogo" className="" />
                </div>
                <MessageBox text='Ads Categories' classname="ms-2 text-break" />
              </NavLink>
            </li>
            <li className="nav-item mt-2">
              <NavLink to={"/ads-list"} className="d-flex align-items-center sideMenu  px-3 position-relative"  >
                <div>
                  <img src={add_list_01} alt="flogo" className="" />
                </div>
                <MessageBox text='Ads List' classname="ms-2 text-break" />
              </NavLink>
            </li>
            <li className="nav-item mt-2">
              <NavLink to={"/ads_plans"} className="d-flex align-items-center sideMenu  px-3  position-relative"  >
                <div>
                  <img src={Ads_Plans_01} alt="flogo" className="" />
                </div>
                <MessageBox text='Ads Plans' classname="ms-2 text-break" />
              </NavLink>
            </li>
            <li className="nav-item mt-2">
              <NavLink to={"/ads_revenue"} className="d-flex align-items-center sideMenu  px-3  position-relative"  >
                <div>
                  <img src={ads_revenue_01} alt="flogo" className="" />
                </div>
                <MessageBox text='Ads Revenue' classname="ms-2 text-break" />
              </NavLink>
            </li>
            <li className="nav-item mt-2">
              <NavLink to={"/calender"} className="d-flex align-items-center sideMenu  px-3  position-relative"  >
                <div>
                  <img src={Calender_Events_01} alt="flogo" className="" />
                </div>
                <MessageBox text='Calender Events' classname="ms-2 text-break" />
              </NavLink>
            </li>
            <li className="nav-item mt-2">
              <NavLink to={"/ads_campaigns"} className="d-flex align-items-center sideMenu  px-3  position-relative"  >
                <div>
                  <img src={Ads_Compaigns_01} alt="flogo" className="" />
                </div>
                <MessageBox text='Ads Compaigns' classname="ms-2 text-break" />
              </NavLink>
            </li>
            <li className="nav-item mt-2">
              <NavLink to={"/ads-time-slot"} className="d-flex align-items-center sideMenu  px-3  position-relative"  >
                <div>
                  <img src={ads_time_slot_01} alt="flogo" className="" />
                </div>
                <MessageBox text='Ads Time Slot' classname="ms-2 text-break" />
              </NavLink>
            </li>
          </li>

          {/* <li className="nav-item mb-4">
            <MessageBox text='Configurations' classname='sideBarHeading theme-text-color  px-3 pb-2 text-start d-block' />
            <NavLink className="d-flex align-items-center sideMenu mb-2 px-3  position-relative" to={"/clear_cache"}>
              <div>
                <img src={Cache_Management_01} alt="flogo" className="" />
              </div>
              <MessageBox text='Cache Management' classname="ms-2 text-break" />
            </NavLink>

            <NavLink className="d-flex align-items-center sideMenu  px-3  position-relative" to={"/debug"}>
              <div>
                <img src={debug_01} alt="flogo" className="" />
              </div>
              <MessageBox text='Debug' classname="ms-2 text-break" />
            </NavLink>
          </li> */}

          <li className="nav-item mb-4">
            <MessageBox text='CONTACT US' classname='sideBarHeading theme-text-color  px-3 pb-2 text-start d-block' />
            <NavLink className="d-flex align-items-center sideMenu mb-2 px-3  position-relative" to={"/contact-us"}>
              <div>
                <img src={contact_request} alt="flogo" className="" />
              </div>
              <MessageBox text='Contact Request' classname="ms-2 text-break" />
            </NavLink>
          </li>

          <li className="nav-item mb-4">
            <MessageBox text='Log Activity' classname='sideBarHeading theme-text-color  px-3 pb-2 text-start d-block' />
            <NavLink className="d-flex align-items-center sideMenu mb-2 px-3  position-relative" to={"/logActivity"}>
              <div>
                <img src={See_Log_Activity_01} alt="flogo" className="" />
              </div>
              <MessageBox text='See Log Activity' classname="ms-2 text-break" />
            </NavLink>
          </li>
          <MessageBox text='FAQ Management' classname='text-start sideBarHeading theme-text-color  px-3 pb-2 d-block' />
          <div className={`nav-link  d-flex align-items-center  justify-content-between sideMenu position-relative  px-3  ${faqURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive'}`} data-bs-toggle="collapse" data-bs-target="#faq-Management" aria-expanded="false" aria-controls="Ads-Management"  >
            <div>
              <img src={See_Log_Activity_01} alt="flogo" className="" />
              <MessageBox text='Manage FAQ' classname="ms-2 text-break" />
            </div>
            <IoIosArrowDown />
          </div>

          <ul id="faq-Management" className={`collapse p-0 mt-2 list-unstyled ${faqURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'} `} >
            {faqURL?.map((data, index) =>
              <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative px-3 ">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break" />
                </NavLink>
              </li>)}
          </ul>

          {/* <li className="nav-item mb-4">
            <MessageBox text='Geo Fencing' classname='sideBarHeading theme-text-color  px-3 pb-2 text-start d-block' />
            <NavLink className="d-flex align-items-center sideMenu  px-3  position-relative" to={"/Geofencing"}>
              <div>
                <img src={Manage_Geo_Fencing_01} alt="flogo" className="" />
              </div>
              <MessageBox text='Manage Geo Fencing' classname="ms-2 text-break" />
            </NavLink>
            <NavLink className="d-flex align-items-center sideMenu  px-3  position-relative" to={"/countries"}>
              <div>
                <img src={Manage_Blocked_Country_01} alt="flogo" className="" />
              </div>
              <MessageBox text='Manage Blocked Country' classname="ms-2 text-break" />
            </NavLink>
          </li> */}
        </ul>
        {/* <li className="nav-item">
            <Link className="nav-link collapsed" to="/libraries">
              <img src={library_01} alt="flogo" className="" />
              <span className="ms-2">Products</span>
            </Link>
          </li> */}

      </div>
    </section>
  );
}
export default Sidebar;